.accountTable {
    display: flex;
    flex-direction: row;
}
.accountTableTd{
    align-items: center;
    height: 30px !important;
    line-height: 1.8;
    margin-left: 0.8px;
    margin-right: 0.8px;
}