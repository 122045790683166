.amountBox {
    background-color: rgba(103, 152, 198, 0.1);
    color: black;
    padding-top: 3px;
    border:1px solid #6798c6 !important;
}

.amountBox.over-limit {
    background-color: #2b3944;
    color: white;
    padding-top: 3px;
}