@font-face {
  font-family: digit;
  src: url(../public/fonts/digit.ttf);
}
@font-face {
  font-family: textfont;
  src: url(../public/fonts/text.ttf);
}
@font-face {
  font-family: mmfont;
  src: url(../public/fonts/pyidaungsu.ttf);
}
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
}
p {
  font-family: textfont;
  font-size: 15px;
}
.number{
  font-family: textfont;
  font-size: 15px !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
