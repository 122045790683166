.calendar {
    background-color: white;
    max-width: 400px;
    width: 95%;  
    border-radius: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
    margin-top: 20px;
}
.calendarTitle {
    background-color: #6798c6;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding:10px 15px;
    color: white;
    font-family: mmfont;
}
.datebox {
    padding:0px 15px 15px 15px;
}