::-webkit-scrollbar {display:none;}
.adminTop {
    display: flex;
    flex-wrap: nowrap;
    padding: 15px 10px;
    background-color: #6798c6;
    align-items: center;
    overflow-x: auto;
}
.adminTop > Button {
    color: white;
    flex:0 0 auto;
    margin-right: 10px;
    padding:4px 10px !important;
}
.adminHomeScreen {
    margin-top: 10px;
    margin-left: 10px;
    background-color: white;
    width: 390px;
    border-radius: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
    padding-bottom: 15px;
    flex:0 0 auto;
    overflow: hidden;
}
.wholeHomeScreen {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 25px;
}
.secondBox {
    flex: 0 0 auto;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
    background-color: white;
    margin-left: 10px;
}
.tableWhole {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.dataBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.digitBox {
    padding:1px 0px;
    background-color: #6798c6;
    border-radius: 5px;
    color: white;
    border: 1px solid #6798c6;
    width: 40px;
    text-align: center;
    cursor: pointer;
    align-self: center;
}
.amountBox {
    padding: 1px 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    margin-left: 3px;
    border-radius: 1px;
    width: 90px;
    overflow: hidden;
    margin:1px;
    margin-left: 1px;
    margin-right: 1px;
    height: 25.9px;
    font-size: 15px;
    font-weight: 500;
}
.secondBoxSecond {
    margin-top: 15px;
    padding:10px;
    background-color: white;
    border-radius: 10px;
}
.secondBoxfirst {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.secondBoxSecondInner {
    margin-top: 10px;
}
.thirdBox {
    flex:0 0 auto;
    margin-top: 10px;
    padding:10px;
    background-color: white;
    margin-left: 10px;
    border-radius: 10px;
}