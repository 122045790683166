.homescreen {
    background-color: white;
    margin-bottom: auto;
    margin-left: 0px;
    margin-right: 0px;
    left: 0px;
    right: 0px;
    max-width: 400px;
    width: 100%;  
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    padding-bottom: 15px;
}
.digitscreen {
    padding: 5px 10px 0px 10px;
    display: flex;
    justify-content:flex-start;
    background-color: #6798c6;
    align-items: center;
}
.topbar {
    width: 55px;
}
.text {
    color:white;
    line-height: 2;
    font-family: 'mmfont';
    font-weight: normal !important;
    font-size: 14.5px;
}
.firstrow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 3.5px 10px;
    margin-left: -10px;
    margin-right: -10px;
}
.buttonBox {
    background-color: #ffd5b5 !important;
    color:black !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    height: 75px;
    width: 24.5%;
    font-size: 17px !important;
    font-weight: 900 !important;
}
#digitBox {
    font-size: 40px !important;
    font-family: 'digit';
    font-weight: normal;
}
.fourthBox {
    height: 153px;
    background-color: #6798c6 !important;
    color:white !important
}
.threeFourthBox {
    height: 300px !important;
}
.finalrow .buttonBox {
    width: 19.5%;
}
.finalbuttonbox {
    width: 49.5% !important;
}
.linkText {
    padding: 10px;
    border-radius: 3px;
    width: 49.5%;
    text-align: center;
}
.custom-input {
    /* Add any desired styling (e.g., border, padding, font) */
    border: 1px solid black;
    padding: 8px;
    font-size: 16px;
    /* Ensure the cursor is visible */
    caret-color: black;
}