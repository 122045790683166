.header-cell {
    padding: 5px;
    border: 1px solid #2b3944;
    margin: 1px;
    background-color: #2b3944;
    color: white;
    text-align: center;
    width: 70px;
  }
  
  .data-cell {
    padding: 5px;
    font-weight: 500;
    font-size: 15px;
    border: 1px solid #6798c6;
    margin: 1px;
    color: black;
    text-align: center;
  }
  
  .data-digit {
    background-color: #6798c6;
    color: white;
  }
  
  .total-cell {
    padding: 5px;
    font-weight: 500;
    font-size: 15px;
    border: 1px solid #6798c6;
    margin: 1px;
    background-color: #2b3944;
    color: white;
    text-align: center;
  }
  