.selectWholeScreen {
    display: flex;
    justify-content: center;
    align-self: center;
    flex-direction: column;
    padding: 20px;
    align-items: center;
}
.selectBox {
    padding: 30px;
    background-color: #6798c6;
    border-radius: 10px;
    margin-top: 10px;
}
.selectBox p {
    font-size: 40px;
    font-weight: 900;
    color: white;
    cursor: pointer;
    font-family: 'digit';
}
.title {
    font-size: 18px;
    font-family: mmfont;
    letter-spacing: 1.5px;
    font-weight: bold;
}