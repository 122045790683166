.historyScreen {
    background-color: white;
    margin-left: 0;
    margin-right: 0;
    max-width: 400px;
    width: 95%;  
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: auto;
}
.historytitle {
    padding: 10px 10px 5px 10px;
    display: flex;
    justify-content:flex-start;
    background-color: #6798c6;
    align-items: center;
}
.historytitle > p {
    color: white;
    font-weight: bold;
}
.tablehead {
    display: flex;
    justify-content: space-between;
    background-color: #6798c6;
}
.tablehead > div > div {
    width: 6000px !important;
}
.tabledata {
    display: flex;
    justify-content: space-between;
}
.tablebox {
    border:1px solid #6798c6;
    text-align: center;
    color: white;
    padding:5px 0px 0px 0px;
    line-height: 1.3;
    font-size: 15px;
}
.tabledata .tablebox {
    display: flex;
    justify-content: center;
    align-items: center;
}
.deltablebox {
    border:1px solid #6798c6 !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    margin-top: 1.5px;
}
.tabledata .tablebox {
    color:black;
    border:1px solid #6798c6;
    padding:5px 0px 5px 0px;
    align-items: center;
    text-align: left;
    margin-top: 1.5px;
}
.historylink {
    width: 30px !important;
    background-color: white !important;
    margin-right: 10px !important;
    text-align: center;
    justify-content: center;
    padding-top: 3px;
    padding-left: 15px !important;
}
input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
  padding: 10px;
}
.historyDate {
    width: 1050px !important;
}