.bodyboxwhole {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}
.bodybox {
    flex:0 0 auto;
    margin-top: 10px;
    background-color: white;
    border-radius: 10px;
    padding:5px;
    margin-left: 10px;
    min-height: 100vh;
    min-width: 98.8%;
}
.bodybox table {
    width: 100%;
}
.tableRow > td {
    justify-content: center;
    padding:0px 10px;
    text-align: center;
    align-items: center;
    align-self: center;
    align-content: center;
    line-height: 2.2;
}
.tableRow > td:nth-child(2) {
    min-width: 100px;
    width: 20%;
}
.tableRow > td:nth-child(3) {
    min-width: 100px;
}
.tableRow > td:nth-child(4) {
    min-width: 60px;
}
.tableRow > td:nth-child(5) {
    min-width: 100px;
}
.tableRow > td:nth-child(6) {
    min-width: 60px;
}
.tableRow > td:nth-child(7) {
    min-width: 100px;
}
.tableRowData > td {
    border: 1px solid #6798c6;
    line-height: 1.8;
    font-size: 15px;
    font-family: 'textfont';
    text-align: right;
}
.arrayRow > td {
    width: 110px !important;
}